import { SubcategorySummary } from '../types';
import { subcategoriesEndpoint } from './endpoints';

export const fetchSubcategories = async (
  accessToken: string,
): Promise<SubcategorySummary[]> => {
  const request = new Request(subcategoriesEndpoint());
  request.headers.append('Authorization', `Bearer ${accessToken}`);

  const apiRes = await fetch(request);

  if (!apiRes.ok) {
    throw new Error('Unable to fetch categories.');
  }

  return apiRes.json();
};
