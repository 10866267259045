import { Center, Spinner } from '@chakra-ui/react'
import React from 'react'

export const Loader = () => {
  return (
    <Center p="24px" m="4rem">
    <Spinner
      thickness="6px"
      speed="0.65s"
      size="xl"
      color="brand.primary"
    />
  </Center>  )
}
