import { ReactNode, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Loader } from './layout/Loader';
import { isUserVerifiedAdminFromToken } from '../helpers/getRolesFromToken';

interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { getAccessTokenSilently, isAuthenticated, loginWithRedirect } =
    useAuth0();
  const [isAdmin, setIsAdmin] = useState(true);

  useEffect(() => {
    const validateAdminRole = async () => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: window.ENV_CONFIG.auth0.audience,
        },
      });

      if (!isUserVerifiedAdminFromToken(accessToken)) {
        setIsAdmin(false);
      }
    };

    validateAdminRole();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (isAuthenticated && !isAdmin) {
      alert(
        'You are not authorized to access this application. Please login using an account with admin permissions.',
      );
      loginWithRedirect({ authorizationParams: { prompt: 'login' } });
    }
  }, [isAuthenticated, loginWithRedirect, isAdmin]);

  return !isAdmin ? <Loader /> : <>{children}</>;
};

export default ProtectedRoute;
