export const body = {
  bg: "#E8E8E85"
}

export const brand = {
  primary: '#009FB5',
  secondary: '#0A92A5',
  white: '#F5F5F5',
  black: '#111827',
  error: '#EF4444',
  lightestGrey: '#E8E8E8',
  tealBlack: "#141818",
  lightTealGrey: '#BFC9CB',
  darkTealGrey: '#363D41',
  teal: '#006873',
  medTeal: '#009FB5',
  darkestTeal: '#0B252B',
  darkestTealGrey: '#21292D',
  charcoalGrey: '#84878B',
}