/*
 * These file uploads don't go via our API - they go straight to Azure Blob
 * Storage. However since we are uploading to a protected container, we must
 * first ask our API for a "Shared Access Signature", which grants this
 * application temporary access to the storage account.
 *
 * So this is a two-step process:
 *   1. POST request to Orders API to generate and return our SAS URL
 *   2. PUT request to the SAS URL (includes the binary data)
 *
 * When we get a successful response from the signed URL request, we might
 * want to send the details of the new file to our API so that we can track
 * it in our database.
 *
 * See: https://docs.microsoft.com/en-us/azure/storage/common/storage-sas-overview
 */

import { BlobImageReference } from '../types';

interface IUntypedDictionary {
  [key: string]: string | number; // dunno what we need yet. string for order thumnail upload
}

interface ISharedAccessSignature {
  uri: string;
  container: string;
  path: string;
}

export async function uploadFileToAzureBlob(
  accessToken: string,
  file: File,
  providerUrl: string,
  fileUploadParams: IUntypedDictionary = {},
): Promise<BlobImageReference> {
  // 1. Get Shared Access Signature (SAS)
  const { uri, container, path } = await getSASUrl(
    accessToken,
    providerUrl,
    file.name,
    fileUploadParams,
  );

  // 2. Upload file using SAS
  await uploadFileToCloud(uri, file);
  const url = `${window.ENV_CONFIG.hosts.azureBlobStorage}/${container}/${path}`;

  const uploadDetails: BlobImageReference = {
    container,
    contentType: file.type,
    filename: file.name,
    path,
    url,
    isFeaturedImage: false,
  };

  return uploadDetails;
}

async function getSASUrl(
  accessToken: string,
  providerUrl: string,
  filename: string,
  fileUploadParams: IUntypedDictionary,
): Promise<ISharedAccessSignature> {
  const fileExtension = getFileExtension(filename);

  const response = await fetch(providerUrl, {
    method: 'POST',
    body: JSON.stringify({
      fileExtension,
      ...fileUploadParams,
    }),
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw Error('Error fetching Shared Access Signature.');
  }

  const json = await response.json();

  return json;
}

export async function uploadFileToCloud(
  sasUrl: string,
  file: File,
): Promise<void> {
  const contentType = file.type;

  const response = await fetch(sasUrl, {
    method: 'PUT',
    body: file,
    headers: {
      'Content-Type': contentType,
      'X-MS-Blob-Type': 'BlockBlob',
    },
  });

  if (!response.ok) {
    throw Error('Error uploading file to cloud storage.');
  }
}

function getFileExtension(filename: string) {
  const extWithoutPeriod = filename.split('.').pop();
  const ext = `.${extWithoutPeriod}`;

  return ext;
}
