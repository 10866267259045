import { subcategoriesEndpoint } from './endpoints';
import { NewSubcategory } from '../types';

export const submitSubcategory = async (
  accessToken: string,
  newSubcategory: NewSubcategory,
): Promise<void> => {
  const request = new Request(subcategoriesEndpoint());

  await fetch(request, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(newSubcategory)
  });
};
