import { subcategoryEndpoint } from './endpoints';
import { Subcategory } from '../types';

export const updateSubcategory = async (
  accessToken: string,
  subcategory: Subcategory,
): Promise<void> => {
  const request = new Request(subcategoryEndpoint(String(subcategory.id)));

  await fetch(request, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(subcategory),
  });
};
