// grab env variables for urls here

const BASE_URL = window.ENV_CONFIG.hosts.orders;
const API_URL = `${BASE_URL}/api/v3`;
export const categoriesEndpoint = () => `${API_URL}/format-categories`;
export const categoryEndpoint = (id: string) =>
  `${API_URL}/format-categories/${id}`;
export const subcategoriesEndpoint = () => `${API_URL}/format-subcategories`;
export const subcategoryEndpoint = (id: string) =>
  `${subcategoriesEndpoint()}/${id}`;
export const sasUrlForImageUpload = () =>
  `${API_URL}/assets/format-subcategory-image`;
export const sizesEndpoint = () => `${API_URL}/sizes`;
