import { CloseIcon } from '@chakra-ui/icons';
import { Button, Flex, HStack, IconButton, Input } from '@chakra-ui/react';
import React from 'react';
import { brand } from '../../theme';
import { NewCategory } from '../../types';

interface AddCategoryProps {
  onSubmit: (newCategory: NewCategory) => void;
  onCancel: () => void;
}

export const AddCategory = ({ onSubmit, onCancel }: AddCategoryProps) => {
  const [inputValue, setInputValue] = React.useState('');

  return (
    <Flex
      width="100%"
      p="12px"
      mb="48px"
      fontSize="lg"
      borderWidth="1px"
      rounded="md"
      justify="space-between"
      align="center"
    >
      <Input
        placeholder="Add Category"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
      />
      <Flex>
        <HStack>
          <IconButton
            size="sm"
            icon={<CloseIcon />}
            aria-label="Edit button"
            onClick={onCancel}
            ml="16px"
          />
          <Button
            size="md"
            bgColor={brand.primary}
            color="white"
            onClick={() => onSubmit({ name: inputValue })}
          >
            Save
          </Button>
        </HStack>
      </Flex>
    </Flex>
  );
};
