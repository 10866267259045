import { categoriesEndpoint } from './endpoints';
import { NewCategory } from '../types';

export const submitCategory = async (
  accessToken: string,
  newCategory: NewCategory,
): Promise<void> => {
  const request = new Request(categoriesEndpoint());

  await fetch(request, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(newCategory)
  });
};
