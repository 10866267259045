import { HStack, Input, Select, VStack, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { Category } from '../../types';

interface DualListProps {
  options: Category[];
  selectedIds?: number[];
  onChange: (values: number[]) => void;
  listName: string;
}

export const DualList = ({
  options = [],
  selectedIds = [],
  onChange,
  listName,
}: DualListProps) => {
  const [optionsSearchValue, setOptionsSearchValue] = useState('');
  const [selectedOptionsSearchValue, setSelectedOptionsSearchValue] =
    useState('');

  const optionsList = options
    .filter((option) =>
      option.name.toUpperCase().includes(optionsSearchValue.toUpperCase()),
    )
    .map((option) => (
      <option
        value={option.id}
        key={option.id}
        style={{ padding: '0.35rem 0.2rem 0.35rem 0.2rem', cursor: 'pointer' }}
        onClick={(e) => handleSelect(Number(option.id))}
      >
        {option.name}
      </option>
    ));

  const selectedOptionsList = options
    .filter(
      (option) =>
        selectedIds.includes(Number(option.id)) &&
        option.name
          .toUpperCase()
          .includes(selectedOptionsSearchValue.toUpperCase()),
    )
    .map((option) => (
      <option
        value={option.id}
        key={option.id}
        style={{ padding: '0.35rem 0.1rem 0.35rem 0.1rem', cursor: 'pointer' }}
      >
        {option.name}
      </option>
    ));

  const handleSelect = (value: number) => {
    if (!selectedIds.includes(value)) {
      onChange([...selectedIds, value]);
    }
  };

  const handleDeselect = (value: number) => {
    onChange(selectedIds.filter((selectedId) => selectedId !== value));
  };

  return (
    <HStack w="100%" gap="2rem">
      <VStack w="100%" align="start">
        <Text mb="4px">Options</Text>
        <Input
          name={`options-${listName}`}
          isRequired={false}
          rounded="md"
          mb={3}
          size="sm"
          w="100%"
          placeholder="Search"
          onChange={(e) => setOptionsSearchValue(e.target.value)}
          id="options-search"
        />
        <Select
          multiple
          minH="250px"
          iconSize="0"
          // isRequired={selectedIds.length === 0 && true}
          isRequired={false}
          title={`${listName}-options`}
          name={`${listName}-options`}
        >
          {optionsList}
        </Select>
        <Text fontSize="xs" mb="24px">
          Click an option to move it to the selected column.
        </Text>
      </VStack>
      <VStack w="100%" align="start">
        <Text mb="4px" fontWeight="bold">
          Selected
        </Text>
        <Input
          name={`selected-options-${listName}`}
          isRequired={false}
          rounded="md"
          mb={3}
          size="sm"
          w="100%"
          placeholder="Search"
          onChange={(e) => setSelectedOptionsSearchValue(e.target.value)}
          id="selected-options-search"
        />
        <Select
          isRequired={false}
          multiple
          minH="250px"
          iconSize="0"
          onChange={(e) => handleDeselect(Number(e.target.value))}
          title={`${listName}-selected-options`}
          name={`${listName}-selected-options`}
        >
          {selectedOptionsList}
        </Select>
        <Text fontSize="xs" mb="24px">
          Click a selected option to remove it.
        </Text>
      </VStack>
    </HStack>
  );
};
