import {
  Box,
  Button,
  Flex,
  Heading,
  VStack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { CategoryTile } from '../components/categories/CategoryTile';
import Layout from '../components/layout/Layout';
import { AddCategory } from '../components/categories/AddCategory';
import { brand } from '../theme';
import { resources } from '../components/resources';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { fetchCategories } from '../api/fetchCategories';
import { useAuth0 } from '@auth0/auth0-react';
import { Loader } from '../components/layout/Loader';
import { submitCategory } from '../api/submitCategory';
import { Category, NewCategory } from '../types';
import { updateCategory } from '../api/updateCategory';

export const Categories = () => {
  const [showAddCategory, setShowAddCategory] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  const toast = useToast();

  const {
    data: categories,
    isLoading,
    isError,
    refetch: refetchCategories,
  } = useQuery({
    queryKey: ['categories'],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: window.ENV_CONFIG.auth0.audience,
        },
      });
      const categories = await fetchCategories(accessToken);
      return categories.sort((a, b) => a.name.localeCompare(b.name));
    },
  });

  const { mutate: newCategoryMutation, isPending: newCategoryIsPending } =
    useMutation({
      mutationFn: async (variables: NewCategory) => {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: window.ENV_CONFIG.auth0.audience,
          },
        });
        return submitCategory(accessToken, variables);
      },
      onSuccess: async () => {
        queryClient.invalidateQueries({ queryKey: ['categories'] });
        await refetchCategories();
        setShowAddCategory(false);
        toast({
          title: 'Category Created',
          description: 'Your category has been created.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      },
    });

  const {
    mutate: updateCategoryMutation,
    isPending: updatedCategoryIsPending,
  } = useMutation({
    mutationFn: async (variables: Category) => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: window.ENV_CONFIG.auth0.audience,
        },
      });
      return updateCategory(accessToken, variables);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['categories'] });
      await refetchCategories();
      setShowAddCategory(false);
      toast({
        title: 'Category Updated',
        description: 'Your category has been updated.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const dataIsLoading =
    isLoading || newCategoryIsPending || updatedCategoryIsPending;

  if (dataIsLoading) {
    return <Loader />;
  }

  if (isError) {
    return <div>Error fetching categories.</div>;
  }

  return (
    <Layout>
      <Box mr="80px">
        <Flex justify="space-between" align="flex-start" mb="40px">
          <VStack spacing="20px" align="flex-start">
            <Heading as="h1" size="xl" mb="8px" color={brand.tealBlack}>
              {resources.categoriesTitle}
            </Heading>
            <Text>Manage categories in hyble Custom Orders.</Text>
          </VStack>

          <Button
            h="60px"
            px="24px"
            rounded="md"
            bgColor={brand.primary}
            color="white"
            ml="24px"
            _hover={{ bgColor: brand.secondary }}
            onClick={() => setShowAddCategory(!showAddCategory)}
          >
            Add Category
          </Button>
        </Flex>

        {showAddCategory && (
          <AddCategory
            onCancel={() => {
              setShowAddCategory(false);
            }}
            onSubmit={(newCategory) => newCategoryMutation(newCategory)}
          />
        )}

        <VStack direction="column">
          {categories?.map((category) => (
            <CategoryTile
              key={category.id}
              category={category}
              onUpdate={(updatedCategory) =>
                updateCategoryMutation(updatedCategory)
              }
            />
          ))}
          )
        </VStack>
      </Box>
    </Layout>
  );
};
