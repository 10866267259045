import React from 'react'
import {Text} from '@chakra-ui/react'

export const Title = () => {
  return (
    <Text ml="24px" mb="42px" fontSize="18">
      Custom Orders: Manage Format Categories    </Text>

    )
}
