import { RouteObject } from "react-router-dom";
import { Categories } from "./pages/Categories";
import { SubcategoryForm } from "./pages/SubcategoryForm";
import { Subcategories } from "./pages/Subcategories";
import ProtectedRoute from "./components/ProtectedRoute";

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <ProtectedRoute><Categories /></ProtectedRoute>,
    children: [],
  },
  {
    path: "/subcategories",
    element: <ProtectedRoute><Subcategories /></ProtectedRoute>,
    children: [],
  },
  {
    path: "/subcategories/edit/:id",
    element: <ProtectedRoute><SubcategoryForm /></ProtectedRoute>,
    children: []
  },
  {
    path: "/subcategories/new",
    element: <ProtectedRoute><SubcategoryForm /></ProtectedRoute>,
    children: []
  },
  {
    path: "*",
    element: <div>Not found</div>,
    children: []
  }
]