import { Flex, Text, HStack, Image, Button, Center } from '@chakra-ui/react';

import { QuestionOutlineIcon } from '@chakra-ui/icons';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SubcategorySummary } from '../../types';

interface SubcategoryTileProps {
  subcategory: SubcategorySummary;
}

export const SubcategoryTile = ({ subcategory }: SubcategoryTileProps) => {
  const navigate = useNavigate();

  return (
    <Flex
      width="100%"
      h="80px"
      p="18px"
      fontSize="lg"
      borderWidth="1px"
      rounded="md"
      alignItems="center"
      justify="space-between"
    >
      <HStack>
        <Center minW="85px">
          <Image
            src={subcategory.featuredImageUrl}
            h={12}
            mr="12px"
            fallback={<QuestionOutlineIcon boxSize={6} minW={'8rem'} />}
          ></Image>
        </Center>
        <Text aria-label={subcategory.name} role="text">{subcategory.name}</Text>
      </HStack>
      <Button
        size="sm"
        aria-label="Edit button"
        onClick={() => navigate(`/subcategories/edit/${subcategory.id}`)}
      >
        Edit
      </Button>
    </Flex>
  );
};
