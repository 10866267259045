import { Box, Button, Flex, Heading, VStack, Text } from "@chakra-ui/react";
import React from "react";
import { SubcategoryTile } from "../components/subcategories/SubcategoryTile";
import Layout from "../components/layout/Layout";
import { brand } from "../theme";
import { resources } from "../components/resources";
import { useQuery } from "@tanstack/react-query";
import { fetchSubcategories } from "../api/fetchSubcategories";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Loader } from "../components/layout/Loader";

export const Subcategories = () => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const { data: subcategories, isLoading, isError } = useQuery({
    queryKey: ['subcategories'], queryFn: async () => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: window.ENV_CONFIG.auth0.audience
        }
      });
      const subcategories = await fetchSubcategories(accessToken);
      return subcategories.sort((a, b) => a.name.localeCompare(b.name));
    }
  })

  if (isLoading) {
    return <Loader/>;
  }

  if (isError) {
    return <div>Error fetching subcategories</div>
  }

  const subcategoriesList = subcategories?.map((subcategory) => (
    <SubcategoryTile key={subcategory.id} subcategory={subcategory} />
  ));

  return (
    <Layout>
      <Box mr="80px">
        <Flex justify="space-between" align="flex-start" mb="40px">
          <VStack spacing="20px" align="flex-start">
            <Heading as="h1" size="xl" mb="8px" color={brand.tealBlack}>
              {resources.subcategoriesTitle}
            </Heading>
            <Text>
              List of format families shown in hyble Custom Orders for users to
              select the right format.
            </Text>
          </VStack>

          <Button
            h="60px"
            px="24px"
            rounded="md"
            bgColor={brand.primary}
            color="white"
            ml="24px"
            _hover={{ bgColor: brand.secondary }}
            onClick={() => navigate('/subcategories/new')}
          >
            Add Format
          </Button>
        </Flex>

        <VStack direction="column">{subcategoriesList}</VStack>
      </Box>
    </Layout>
  );
};
