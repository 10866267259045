import React, { ReactNode } from 'react';
import { Grid, GridItem } from '@chakra-ui/react';
import Header from '../header/Header';
import { Title } from './Title';
import { SideMenu } from './SideMenu';

interface LayoutWrapperProps {
  children: ReactNode;
}

const WrapperComponent = ({ children }: LayoutWrapperProps) => {
  return (
    <Grid
      templateRows="repeat(3, 1fr)"
      templateColumns="repeat(4, 1fr)"
      h="0"
    >
      <GridItem rowSpan={1} colSpan={4}>
        <Header />
      </GridItem>
      <GridItem rowSpan={1} colSpan={4} ml="1rem">
        <Title />
      </GridItem>
      <GridItem colSpan={1} ml="1rem">
        <SideMenu />
      </GridItem>
      <GridItem colSpan={3} mr="4rem" pb="100px">
        {children}
      </GridItem>
    </Grid>
  );
};

export default WrapperComponent;
