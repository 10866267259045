import { categoryEndpoint } from './endpoints';
import { Category } from '../types';

export const updateCategory = async (
  accessToken: string,
  category: Category,
): Promise<void> => {
  const request = new Request(categoryEndpoint(String(category.id)));

  await fetch(request, {
    method: 'PUT',
    body: JSON.stringify({ name: category.name }),
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
};
