import { Button, Flex, Heading } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { brand } from '../../theme';
import { resources } from '../resources';

export const SideMenu = () => {
  return (
    <Flex ml="24px" mr="24px" direction="column">
      <Heading as="h2" size="lg" mb="28px" color={brand.tealBlack}>
        Menu
      </Heading>
      <Link to="/">
        <Button {...optionStyles} role="link">
          {resources.categoriesTitle}
        </Button>
      </Link>
      <Link to="/subcategories">
        <Button {...optionStyles} role="link">
          {resources.subcategoriesTitle}
        </Button>
      </Link>
    </Flex>
  );
};

const optionStyles = {
  color: brand.tealBlack,
  mb: '20px',
  fontSize: '16px',
  fontWeight: '600',
  _hover: { opacity: 0.5, color: brand.secondary },
  variant: 'link',
  textColor: 'brand.tealBlack',
};
