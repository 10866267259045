export const verifyImagesSize = async (uploadedImages: File[], { maxImageHeight, maxImageWidth }: { maxImageHeight: number, maxImageWidth: number}) => {
  const errors: string[] = [];
  const promises = uploadedImages.map((image) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(image);
      img.onload = () => {
        if (img.height > maxImageHeight || img.width > maxImageWidth) {
          errors.push(image.name);
        }
        resolve(errors);
      };
    });
  });

  return Promise.all(promises).then(() => {
    return errors;
  });
};