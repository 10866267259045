import { Subcategory } from "../types";
import { subcategoryEndpoint } from "./endpoints";

export const fetchSubcategory = async (accessToken: string, id: string): Promise<Subcategory> => {
    const request = new Request(subcategoryEndpoint(id));
    request.headers.append('Authorization', `Bearer ${accessToken}`);

    const apiRes = await fetch(request)

    if (!apiRes.ok) {
        throw new Error('Unable to fetch subcategory.');
    }

    return apiRes.json();
}