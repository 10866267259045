import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import {
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { body, brand } from './theme';
import { routes } from './routes';

const queryClient: QueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
});

// TODO: Update with constants for colors, fonts, etc.
const chakraTheme = extendTheme({
  styles: {
    global: {
      body,
    },
  },
  colors: {
    brand,
  },
  components: {
    FormLabel: {
      baseStyle: {
        fontWeight: 'bold',
        fontSize: '20px',
        color: 'brand.tealBlack',
        marginBottom: '12px'
      },
    },
  },
});

function App() {
  const { isLoading, isAuthenticated, loginWithRedirect } =
    useAuth0();

  if (!isAuthenticated && !isLoading) {
    loginWithRedirect({ authorizationParams: { prompt: 'login' }});
  }

  // See ReactRouter docs: https://reactrouter.com/en/main/start/overview#nested-routes
  const router = createBrowserRouter(routes);

  return (
    <ChakraProvider theme={chakraTheme}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </ChakraProvider>
  );
}

export default App;
