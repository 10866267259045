import { Category } from "../types";
import { categoriesEndpoint } from "./endpoints";

export const fetchCategories = async (accessToken: string): Promise<Category[]> => {
    const request = new Request(categoriesEndpoint());
    request.headers.append('Authorization', `Bearer ${accessToken}`);

    const apiRes = await fetch(request)

    if (!apiRes.ok) {
        throw new Error('Unable to fetch categories.');
    }

    return apiRes.json();
}