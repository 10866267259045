import { jwtDecode } from 'jwt-decode';
import { DecodedAccessToken } from '../types';

export const getRolesFromToken = (accessToken: string) => {
  const decodedToken: DecodedAccessToken = jwtDecode(accessToken);
  return decodedToken['https://mrmglobal.com/role'];
};

export const isUserVerifiedAdminFromToken = (accessToken: string) => {
  const roles = getRolesFromToken(accessToken);
  return roles.includes('mrm_admin');
};
