import {
  Flex,
  Input,
  Text,
  Box,
  Image as ChakraImage,
  Wrap,
  WrapItem,
  FormLabel,
} from '@chakra-ui/react';
import React, { ChangeEvent, useState } from 'react';
import { sasUrlForImageUpload } from '../../api/endpoints';
import { uploadFileToAzureBlob } from '../../api/uploadFileToAzureBlob';
import { BlobImageReference, SubcategoryImage } from '../../types';
import { useAuth0 } from '@auth0/auth0-react';
import { Loader } from '../layout/Loader';
import { verifyImagesSize } from './verifyImagesSize';

interface IUploadImagesProps {
  images?: (SubcategoryImage | BlobImageReference)[];
  setFieldValue: (field: string, value: any) => void;
}

const maxImageHeight = 960;
const maxImageWidth = 1200;

export const UploadImages = ({
  images = [],
  setFieldValue,
}: IUploadImagesProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const [isUploadingImage, setIsUploadingImage] = useState(false);

  const onFileUpload = async (
    event: ChangeEvent<HTMLInputElement> | undefined,
  ) => {
    if (!event?.target.files) return;
    setIsUploadingImage(true);
    const uploadedimages = Array.from(event.target.files);

    const invalidImages = await verifyImagesSize(uploadedimages, { maxImageHeight, maxImageWidth });
    if (invalidImages.length > 0) {
      alert(
        `The following images are not the correct size: ${invalidImages.join(
          ', ',
        )}. Please upload images that are ${maxImageWidth} x ${maxImageHeight}px`,
      );
      setIsUploadingImage(false);
      return;
    }

    const sasUrl = sasUrlForImageUpload();

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: window.ENV_CONFIG.auth0.audience,
      },
    });

    const promises = uploadedimages.map(async (file) =>
      uploadFileToAzureBlob(accessToken, file, sasUrl),
    );

    Promise.all(promises).then((responses) => {
      // if there is no featured image, set the first image as featured
      if (images.length === 0) {
        responses[0].isFeaturedImage = true;
      }
      setFieldValue('images', [...images, ...responses]);
      setIsUploadingImage(false);
    });
  };

  const onImageClick = (imageUrl: string) => {
    const newImages = images.map((image) => {
      image.url === imageUrl
        ? (image.isFeaturedImage = true)
        : (image.isFeaturedImage = false);
      return image;
    });

    setFieldValue('images', newImages);
  };

  const featuredImage = images.find((image) => image.isFeaturedImage);

  return (
    <Flex direction="column" w="100%">
      <FormLabel htmlFor="images">Images</FormLabel>
      <Text mb="24px">Add images for the format family.</Text>
      <Input
        data-testid="image-upload"
        type="file"
        id="image-upload"
        name="images"
        title="images"
        pt="18px"
        pb="48px"
        mb="12px"
        onChange={onFileUpload}
        multiple
        accept="image/png, image/jpeg"
        isRequired={images.length === 0}
        color="transparent"
      />
      <Text fontSize="xs" mb="12px" color="grey.600">
        Recommended image size is 1000 x 800px. Max image size is{' '}
        {maxImageWidth} x {maxImageHeight}px. Accepted file types: PNG / JPG.
      </Text>

      {images.length === 0 && isUploadingImage && <Loader />}
      {images.length > 0 && (
        <>
          <Box
            p="24px"
            bgColor="brand.lightestGrey"
            my="20px"
            rounded="md"
            w="100%"
          >
            <Wrap spacing="2rem" align="center">
              {isUploadingImage ? (
                <Loader />
              ) : (
                images.map((image) => (
                  <WrapItem
                    onClick={() => onImageClick(image.url)}
                    key={image.url}
                  >
                    <Box
                      _hover={{
                        cursor: 'pointer',
                        bgColor: 'gray.300',
                        borderRadius: '10px',
                      }}
                      w="8rem"
                      p="12px"
                      bgColor={image.isFeaturedImage ? 'blue.100' : 'none'}
                      borderRadius={image.isFeaturedImage ? '10px' : 'none'}
                    >
                      <ChakraImage
                        src={image.url}
                      ></ChakraImage>
                    </Box>
                  </WrapItem>
                ))
              )}
            </Wrap>
          </Box>

          <Text fontSize="xs" mb="24px" color="grey.600">
            Click an image above to select the featured image.
          </Text>
          <Text mb="8px" fontWeight="bold">
            Current featured image
          </Text>
          <Box
            p="10px"
            bgColor="blue.100"
            rounded="md"
            w="fit-content"
            mt="12px"
            mb="24px"
          >
            <ChakraImage
              alt="Featured image"
              src={featuredImage?.url}
              h="10rem"
            ></ChakraImage>
          </Box>
        </>
      )}
    </Flex>
  );
};
