import { ChevronLeftIcon } from "@chakra-ui/icons";
import React from "react";

import { useAuth0 } from "@auth0/auth0-react";
import HybleLogo from "../../assets/HybleLogo";

import {
  Box,
  HStack,
  Icon,
  Link,
  Button, Flex, Spacer, Text
} from '@chakra-ui/react';
import LogoutIcon from './LogoutIcon';
import SupportIcon from './SupportIcon';

const Header = () => {
  const { logout } = useAuth0();
  return (
    <Flex
      minH="60px"
      bg="gray.800"
      alignItems="center"
      zIndex="10"
      mb="32px"
      px="24px"
    >
      <HybleLogo />

      <Button variant="ghost" sx={buttonStyles} pb="2px">
        <ChevronLeftIcon color="white" mr="4px" />
        <Text
          as="a"
          color="white"
          fontSize="12px"
          fontWeight="700"
          href={window.ENV_CONFIG.mrmToolkitUrl}
        >
          Back to Toolkit
        </Text>
      </Button>

      <Spacer />

      <HStack spacing={4}>
        <Link
          href={'https://support.hyble.tech/'}
          isExternal
          color={'brand.primary'}
          _hover={{ color: 'brand.teal' }}
          rel="noreferrer"
          style={{ cursor: 'pointer' }}
        >
          <Icon w="24px" h="24px" as={SupportIcon} />
        </Link>
        <Box
          color={'brand.primary'}
          _hover={{ color: 'brand.teal' }}
          onClick={() =>
            logout({ logoutParams: { returnTo: window.location.origin } })
          }
          style={{ cursor: 'pointer' }}
        >
          <Icon as={LogoutIcon} w="24px" h="24px" />
        </Box>
      </HStack>
    </Flex>
  );
};

export default Header;

const buttonStyles = {
  color: "white",
  fontSize: "14px",
  fontWeight: "700",
  _hover: { opacity: 0.7 },
};
