import {
  ButtonGroup,
  Editable,
  Flex,
  Input,
  useEditableControls,
  EditablePreview,
  EditableInput,
  Button,
} from '@chakra-ui/react';
import { useState } from 'react';
import { Category } from '../../types';

interface CategoryTileProps {
  category: Category;
  onUpdate: (updatedCategory: Category) => void;
}

export const CategoryTile = ({ category, onUpdate }: CategoryTileProps) => {
  const [editValue, setEditValue] = useState(category.name);

  const EditableControls = () => {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    return isEditing ? (
      <ButtonGroup justifyContent="center" size="sm">
        <Button {...getCancelButtonProps()}>
          Cancel
        </Button>
        <Button
          _hover={{ bgColor: 'green.100' }}
          {...getSubmitButtonProps()}
          onClick={() => {
            onUpdate({ id: category.id, name: editValue });
          }}
        >
          Save
        </Button>
      </ButtonGroup>
    ) : (
      <ButtonGroup justifyContent="center" size="sm">
        {/* <Button
          _hover={{ bgColor: 'red.100' }}
          size="sm"
          aria-label="Edit button"
        >
          Delete
        </Button> */}
        <Button size="sm" {...getEditButtonProps()}>
          Edit
        </Button>
      </ButtonGroup>
    );
  };

  return (
    <Editable
      width="100%"
      p="12px"
      defaultValue={category.name}
      fontSize="lg"
      isPreviewFocusable={false}
      borderWidth="1px"
      rounded="md"
    >
      <Flex justify="space-between" alignItems="center">
        <EditablePreview />
        <Input
          as={EditableInput}
          mr="18px"
          value={editValue}
          onChange={(e) => setEditValue(e.target.value)}
        />
        <EditableControls />
      </Flex>
    </Editable>
  );
};
