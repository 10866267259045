import { useFormik } from 'formik';
import Header from '../components/header/Header';
import {
  Box,
  Flex,
  Heading,
  VStack,
  Text,
  Input,
  Textarea,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  useToast,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { brand } from '../theme';
import * as Yup from 'yup';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { startCase } from 'lodash';

import { fetchSubcategory } from '../api/fetchSubcategory';
import { DualList } from '../components/subcategories/DualList';
import { NewSubcategory, Subcategory } from '../types';
import { UploadImages } from '../components/subcategories/UploadImages';
import { fetchCategories } from '../api/fetchCategories';
import { fetchSizes } from '../api/fetchSizes';
import { updateSubcategory } from '../api/updateSubcategory';
import { submitSubcategory } from '../api/submitSubcategory';
import { Loader } from '../components/layout/Loader';

export const SubcategoryForm = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const queryClient = useQueryClient();

  const toast = useToast();
  const navigate = useNavigate();

  const { id } = useParams();
  const isNew = !id;

  const {
    data: subcategory,
    isPending: subcategoryIsPending,
    isError: subcategoryIsError,
  } = useQuery({
    queryKey: ['subcategory', id],
    queryFn: async () => {
      if (isNew) return Promise.resolve(null);

      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: window.ENV_CONFIG.auth0.audience,
        },
      });
      return fetchSubcategory(accessToken, id);
    },
  });

  const {
    data: categories,
    isPending: categoriesIsPending,
    isError: categoriesIsError,
  } = useQuery({
    queryKey: ['categories'],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: window.ENV_CONFIG.auth0.audience,
        },
      });
      return fetchCategories(accessToken);
    },
  });

  const {
    data: sizes,
    isPending: sizesIsPending,
    isError: sizesIsError,
  } = useQuery({
    queryKey: ['sizes'],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: window.ENV_CONFIG.auth0.audience,
        },
      });
      return fetchSizes(accessToken);
    },
  });

  const { mutate: updateSubcategoryMutation } = useMutation({
    mutationFn: async (subcategory: Subcategory) => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: window.ENV_CONFIG.auth0.audience,
        },
      });
      return updateSubcategory(accessToken, subcategory);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['subcategories'] });
      await queryClient.invalidateQueries({ queryKey: ['subcategory', id] });
      navigate('/subcategories');
      toast({
        title: 'Format Updated',
        description: 'Your format family has been updated.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const { mutate: newSubcategoryMutation } = useMutation({
    mutationFn: async (subcategory: NewSubcategory) => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: window.ENV_CONFIG.auth0.audience,
        },
      });
      return submitSubcategory(accessToken, subcategory);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['subcategories'] });
      await queryClient.invalidateQueries({ queryKey: ['subcategory', id] });
      navigate('/subcategories');
      toast({
        title: 'Format Added',
        description: 'Your format family has been created.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: isNew ? '' : subcategory?.name ?? '',
      description: isNew ? '' : subcategory?.description ?? '',
      images: isNew ? [] : subcategory?.images ?? [],
      selectedFormatsSizes: isNew
        ? []
        : subcategory?.selectedFormatsSizes ?? [],
      selectedCategories: isNew ? [] : subcategory?.selectedCategories ?? [],
    },
    onSubmit(values) {
      if (isNew) {
        const newSubcategory = {
          name: values.title,
          description: values.description,
          images: values.images,
          selectedFormatsSizes: values.selectedFormatsSizes,
          selectedCategories: values.selectedCategories,
        };

        newSubcategoryMutation(newSubcategory);
      } else {
        const subCategory: Subcategory = {
          id: id,
          name: values.title,
          description: values.description,
          images: values.images,
          selectedFormatsSizes: values.selectedFormatsSizes,
          selectedCategories: values.selectedCategories,
        };

        updateSubcategoryMutation(subCategory);
      }
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .min(3, 'Must be 3 characters or more')
        .max(255, 'Must be 255 characters or less')
        .required('This is a required value'),
      description: Yup.string()
        .max(1000, 'Must be 1000 characters or less')
        .required('This is a required value'),
      images: Yup.array().min(1, 'Must have at least one image').required(),
    }),
  });

  const dataHasLoaded =
    !subcategoryIsPending &&
    !categoriesIsPending &&
    categories &&
    !sizesIsPending &&
    sizes;

  const dataError = subcategoryIsError || categoriesIsError || sizesIsError;

  if (!dataHasLoaded) return <Loader />;
  if (dataError) return <div>Error Loading data</div>;

  const {
    values: {
      title,
      description,
      images,
      selectedFormatsSizes,
      selectedCategories,
    },
    errors,
    isSubmitting,
    setFieldValue,
    handleSubmit,
  } = formik;

  const showFormErrors = () => {
    Object.entries(errors).forEach(([key, value]) => {
      const title = startCase(key);
      toast({
        title: `Error: ${title}`,
        description: `${value}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    });
  };

  return (
    <Box>
      <Header />
      <Flex direction="column" mx="12rem">
        <Flex mt="20px" mb="40px" ml="8vw" align="center">
          <ChevronLeftIcon mr="4px" />
          <Button onClick={onOpen} variant="link" textColor="brand.tealBlack">
            Back
          </Button>

          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Leave page with unsaved changes?</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                Leaving this page will delete all unsaved changes.
              </ModalBody>

              <ModalFooter>
                <Button onClick={onClose} variant="ghost" mr="8px">
                  Stay
                </Button>
                <Button _hover={{ bg: '#E73D47' }} onClick={onClose} mr="8px">
                  <Link to="/subcategories">Leave Page</Link>
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Flex>

        <form onSubmit={handleSubmit}>
          <VStack align="start" w="100%" gap="3rem" px="8vw">
            <Flex direction="column" gap="1rem">
              <Heading as="h1" size="xl" color={brand.tealBlack}>
                Create Format
              </Heading>
              <Text>Complete all fields.</Text>
            </Flex>
            <Flex direction="column" w="100%">
              <FormControl isRequired>
                <Flex>
                  <FormLabel
                    htmlFor="title"
                    size="lg"
                    mb="12px"
                    color={brand.tealBlack}
                  >
                    Title
                  </FormLabel>
                </Flex>
                <Text mb="24px">Give the format family a name.</Text>
                <Input
                  id="title"
                  name="title"
                  type="text"
                  mb="8px"
                  onChange={(e) => setFieldValue('title', e.target.value)}
                  value={title}
                  maxLength={255}
                />
                <Text fontSize="xs" mb="24px">
                  Max characters: 255
                </Text>
              </FormControl>
            </Flex>

            <Flex direction="column" w="100%">
              <FormControl isRequired>
                <FormLabel htmlFor="description">Short Description</FormLabel>
                <Text mb="24px">Describe your item.</Text>
                <Textarea
                  id="description"
                  name="description"
                  onChange={(e) => setFieldValue('description', e.target.value)}
                  value={description}
                  mb="8px"
                  maxLength={1000}
                />
                <Text fontSize="xs" mb="24px">
                  Max characters: 1000
                </Text>
              </FormControl>
            </Flex>

            <FormControl isRequired>
              <UploadImages images={images} setFieldValue={setFieldValue} />
            </FormControl>

            <Flex direction="column" w="100%">
              <FormControl isRequired>
                <FormLabel htmlFor="selected-options-selected-formats-sizes">
                  Format & Size Options
                </FormLabel>
                <Text mb="48px">
                  These are the specific formats and sizes of this format family
                  that can be selected by a sales rep. Select all that are
                  relevant for this format family.
                </Text>
                <DualList
                  options={sizes}
                  selectedIds={selectedFormatsSizes}
                  onChange={(values) =>
                    setFieldValue('selectedFormatsSizes', values)
                  }
                  listName="selected-formats-sizes"
                />
              </FormControl>
            </Flex>

            <Flex direction="column" w="100%">
              <FormControl isRequired>
                <FormLabel htmlFor="selected-options-selected-categories">
                  Categories
                </FormLabel>
                <Text mb="48px">
                  Select the categories that this format family belongs to.
                </Text>
                <DualList
                  options={categories}
                  selectedIds={selectedCategories}
                  onChange={(values) =>
                    setFieldValue('selectedCategories', values)
                  }
                  listName="selected-categories"
                />
              </FormControl>
            </Flex>
            <Flex justify="flex-end" mb="40px" w="100%">
              <Button
                isLoading={isSubmitting}
                h="60px"
                px="24px"
                rounded="md"
                bgColor={brand.primary}
                color="white"
                ml="24px"
                _hover={{ bgColor: brand.secondary }}
                type="submit"
                onClick={showFormErrors}
              >
                Publish
              </Button>
            </Flex>
          </VStack>
        </form>
      </Flex>
    </Box>
  );
};
