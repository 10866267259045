import { Size } from '../types';
import { sizesEndpoint } from './endpoints';

export interface SizesResponse {
    id: number;
    displayName: string;
}

export const fetchSizes = async (accessToken: string): Promise<Size[]> => {
  const request = new Request(sizesEndpoint());
  request.headers.append('Authorization', `Bearer ${accessToken}`);

  const apiRes = await fetch(request);

  if (!apiRes.ok) {
    throw new Error('Unable to fetch sizes.');
  }

  const response = await apiRes.json();
  return response.map((size: SizesResponse) => ({ id: size.id, name: size.displayName }));
};
